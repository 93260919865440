import { Box, Flex, IconButton, Text, Stack, Divider } from "@chakra-ui/react";
import { FaInstagram, FaPinterest, FaLinkedin, FaTiktok, FaYoutube } from "react-icons/fa";

const Footer = () => (
  <Box bg="black" color="white">
    <Divider borderColor="gray.600" />
    <Flex
      direction={["column", "row"]}
      align={["center", "center"]}
      justify="space-between"
      maxW="1200px"
      mx="auto"
      px={5}
      py={8}
      textAlign={["center", "left"]}
    >
      <Text color="gray.600" size="sx">&copy; 2024 Miller Productions Kft. Minden jog fenntartva</Text>

      <Stack
        direction="row"
        spacing={3}
        mt={[4, 0]}
      >
        {/* <IconButton as="a" href="#" icon={<FaInstagram />} aria-label="Instagram" variant="ghost" colorScheme="whiteAlpha" />
        <IconButton as="a" href="#" icon={<FaPinterest />} aria-label="Pinterest" variant="ghost" colorScheme="whiteAlpha" /> */}
        <IconButton as="a" href="https://www.linkedin.com/company/miller-productions" icon={<FaLinkedin />} aria-label="LinkedIn" variant="ghost" colorScheme="whiteAlpha" />
        {/* <IconButton as="a" href="#" icon={<FaTiktok />} aria-label="TikTok" variant="ghost" colorScheme="whiteAlpha" />
        <IconButton as="a" href="#" icon={<FaYoutube />} aria-label="YouTube" variant="ghost" colorScheme="whiteAlpha" /> */}
      </Stack>
    </Flex>
  </Box>
);

export default Footer;
