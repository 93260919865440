import {
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

const faq = [
  {
    question: "Mennyibe fog kerülni a szolgáltatás?",
    answer:
      "A szolgáltatás költségei előfizetéses modellben alakulnak, amely általában havi vagy éves díjat jelent. Az árak a választott csomagtól és a felhasználói igényektől függően változhatnak. Részletes információt a weboldalunkon találhat a különböző csomagokról és azok árazásáról.",
  },
  {
    question: "Milyen szintű biztonságot nyújt a SaaS rendszer?",
    answer:
      "Rendszerünk magas szintű biztonsági intézkedéseket alkalmaz, beleértve az adatok titkosítását, tűzfalakat és rendszeres biztonsági mentéseket. Adatkezelési gyakorlatunk megfelel a legújabb ipari szabványoknak, hogy megvédjük felhasználóink személyes és üzleti adatait.",
  },
  {
    question: "Milyen támogatást nyújtanak a felhasználók számára?",
    answer:
      "Ügyfélszolgálatunk két csatornán elérhető, e-mailt, valamint online időpontot lehet foglalni konzultációra.",
  },
  {
    question: "Mennyire rugalmas a rendszer a jövőbeli igényekhez való alkalmazkodásban?",
    answer:
      "SaaS rendszerünk rugalmasan skálázható, így könnyedén bővítheti vagy csökkentheti a felhasználói számot és a funkciókat az igényeinek megfelelően. Rendszeresen frissítjük a szoftvert új funkciókkal és fejlesztésekkel, hogy lépést tudjunk tartani a változó piaci igényekkel.",
  },
];

function FAQAccordion() {
  return (
    <Box
      id="faq"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mb={32}
      maxW="1400px"
      mx="auto"
      px={{ base: 4, md: 8 }}
    >
      <Text fontSize="4xl" fontWeight="bold" textAlign="center">
        Kérdések, amik felmerülhetnek
      </Text>

      <Accordion allowToggle mt={8} width={{ base: "100%", md: "80%" }} maxW="1200px">
        {faq.map((item, index) => (
          <AccordionItem key={index}>
            <AccordionButton
              _expanded={{ bg: "purple.100", color: "purple.800" }}
              py={6}
            >
              <Box flex="1" textAlign="left" fontSize="xl">
                {item.question}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={6} fontSize="lg">
              {item.answer}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}

export default FAQAccordion;