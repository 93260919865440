// components/Hero.tsx
import { Box, Heading, Text } from "@chakra-ui/react";

const Hero = () => {
  return (
    <Box
      id="home"
      position="relative"
      bgGradient="linear-gradient(#000000, #5D2CA8, #000000)"
      height="700px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      color="white"
      textAlign="center"
      overflow="hidden"
    >
      {/* Main Heading */}
      <Heading as="h1" fontSize={{ base: "3xl", md: "5xl", lg: "6xl" }} mb={4}>
        Fejlessze Vállalkozását Velünk!
      </Heading>

      {/* Subheading */}
      <Text as="h2" fontSize={{ base: "md", md: "xl" }} mb={4}>
        Izgalmas SaaS megoldások nem csak üzleti ügyfeleknek
      </Text>

      {/* Description */}
      <Text as="h2" fontSize={{ base: "sm", md: "lg" }} maxW="600px">
        Testreszabott szoftvermegoldások a hatékonyság növelésére és az üzleti
        növekedés felgyorsítására.
      </Text>

      {/* Ellipse at the Bottom */}
      <Box
        position="absolute"
        bottom="-100px"
        width="150%"
        height="200px"
        bgGradient="radial-gradient(closest-side, #000000 60%, #5D2CA8 90%, #B48CDE 100%)"
        borderRadius="50%"
        zIndex={1}
      />
    </Box>
  );
};

export default Hero;
