import logo from "../assets/logo/logo_white.png";
import {
  Flex,
  Image,
  Link,
  Box,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleScroll = (id: string) => {
    onClose();

    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      } else {
        console.error(`Section with id "${id}" not found!`);
      }
    }, 300);
  };

  return (
    <Box width="100%" maxW="1400px" mx="auto" p={5}>
      <Flex justify="space-between" align="center">
        <Image w={100} src={logo} ml={50} />

        <IconButton
          display={{ base: "block", md: "none" }}
          icon={<HamburgerIcon boxSize={7} />}
          aria-label="Open menu"
          onClick={onOpen}
          position="absolute"
          top={5}
          right={5}
          backgroundColor="black"
          color="white"
          _hover={{ bg: "gray.700" }}
        />

        <Flex gap={5} display={{ base: "none", md: "flex" }}>
          <Link onClick={() => handleScroll("home")}>Főoldal</Link>
          <Link onClick={() => handleScroll("features")}>Funkciók</Link>
          <Link onClick={() => handleScroll("products")}>Termékek</Link>
          <Link onClick={() => handleScroll("faq")}>Kérdések</Link>
          <Link onClick={() => handleScroll("contacts")}>Kapcsolat</Link>
        </Flex>
      </Flex>

      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody bgColor="black">
            <Flex direction="column" gap={5} mt={10}>
              <Link onClick={() => handleScroll("home")}>Főoldal</Link>
              <Link onClick={() => handleScroll("features")}>Funkciók</Link>
              <Link onClick={() => handleScroll("products")}>Termékek</Link>
              <Link onClick={() => handleScroll("faq")}>Kérdések</Link>
              <Link onClick={() => handleScroll("contacts")}>Kapcsolat</Link>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;
