import { ChakraProvider } from "@chakra-ui/react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import ProductDisplay from "./components/ProductDisplay";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import theme from "./theme"

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Hero />
      <Features />
      <ProductDisplay />
      <FAQ />
      <Footer />
    </ChakraProvider>
  );
}

export default App;
